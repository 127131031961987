<template>
 <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  beforeCreate(){
    const user = sessionStorage.getItem('ENSPSID');
      // console.log(user);

      if (user) {
        this.$store.dispatch('school/getSchools')
        this.$store.dispatch('school/getCampusses')
        this.$store.dispatch('school/getDepartments')
        this.$store.dispatch('school/getPrograms')
        this.$store.dispatch('school/getLevels')
        this.$store.dispatch('school/getAcademicYears')

        //dispatch to get user 
        this.$store.dispatch('authfack/getProfile')
        this.$store.dispatch('user/getPermissions')

      }
  }

}
</script>
