/// in this file you will buiild an axios interceptor 

import axios from 'axios';

import endpoint from './endpoint';

import router from '@/router'; // Import your router instance

let api  = axios.create({
    baseURL: endpoint,
});

api.interceptors.request.use(
    (config) => {

      // const user = JSON.parse(sessionStorage.getItem('ENSPSID'));
      const token = sessionStorage.getItem('ENSPSID');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  // Add a response interceptor
  api.interceptors.response.use(

    (response) => {
      return response;
    },
 
    (error) => {
      if (error.response.status === 401) {
        // Perform logout
        // console.log("this is ");
        // localStorage.removeItem('user');
        sessionStorage.removeItem('ENSPSID');
        router.push('/login'); // Redirect to login page
        location.reload(true);

      }

      return Promise.reject(error);

    }
  );
  
  
export default  api;
